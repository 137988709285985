import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = ({ isOpen, toggle, action, message, type }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={"static"}>
      <ModalHeader>{message}</ModalHeader>
      <ModalFooter>
        <Button color="btn btn-light" onClick={toggle}>
          Cancel
        </Button>
        <Button color="btn btn-success" onClick={action}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
