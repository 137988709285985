import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setCredentials } from "../../features/auth/authSlice";
import axios from "axios";
import { authTypes } from "../../enums/authTypes";
import { getAuthURL } from "../../components/common/utils/utils";

const authURL = getAuthURL();

const baseQuery = fetchBaseQuery({
  baseUrl: `${
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_API_URL
      : process.env.PUBLIC_URL
  }/v1/`,
  credentials: "include",

  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    headers.set("Accept", "*/*");
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.meta?.response?.status === 401) {
    try {
      // const token = api.getState().auth.token;

      const refreshResult = await axios.post(
        `${authURL}/v1/auth/tokens:refresh`,
        null,
        {
          withCredentials: true,
        }
      );

      if (refreshResult?.data?.token) {
        const user = api.getState().auth.user;

        api.dispatch(
          setCredentials({ token: refreshResult?.data?.token, user })
        );
        localStorage.setItem("token", refreshResult?.data?.token);

        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut());
      }
    } catch (error) {
      console.log(error);
      api.dispatch(logOut());
      window.location.href = `${authURL}/#/portal?continue=${window.location.origin}?authtype=${authTypes.TRUE}`;
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Invoices",
    "MyInvoices",
    "ApprovalRulesFields",
    "InvoiceDetail",
    "ProcessingStatuses",
    "ReviewStatuses",
    "ReviewTypes",
    "ApprovalRules",
    "InvoiceInsights",
    "MyInvoiceInsights",
    "approvalRequireInvoices",
    "requireToApproval",
    "Comments",
  ],
  endpoints: (builder) => ({}),
});
