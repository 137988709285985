import React, { useEffect, useState, useRef } from "react";
import DataTable from "../../components/common/DataTable/DataTable";
import searchIcon from "../../assets/images/Icons/search.png";
import "../InvoiceManager/InvoiceManager.css";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { ReviewStatus, ProcessingStatus } from "../../enums/statusEnums";
import { InvoiceTypes } from "../../enums/invoiceTypes";
import {
  useGetInvoicesQuery,
  useDeleteInvoiceMutation,
  useResubmitInvoiceMutation,
  useAssignInvoiceMutation,
} from "../InvoiceManager/invoiceSlice";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AlertModal from "../../components/common/AlertModal/AlertModal";
import toast, { Toaster } from "react-hot-toast";
import EditInvoice from "../../components/InvoiceManager/EditInvoice/EditInvoice";
import { useGetUsersQuery } from "../Users/UsersSlice";
import InvoiceAssigneeModal from "../../components/InvoiceAssigneeModal/InvoiceAssigneeModal";
import TableSearch from "../../components/TableSearch/TableSearch";
import { useErrorToast } from "../../hooks/useErrorToast";
import InvoiceInsights from "../../components/common/InvoiceInsights/InvoiceInsights";

const FailedInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [assignToModalIsOpen, setAssignToModalIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceGuid, setInvoiceGuid] = useState("");
  const showErrorToast = useErrorToast();

  const navigate = useNavigate();

  const {
    data: invoiceData,
    isLoading: isInvoiceDataLoading,
    isFetching: isInvoiceDataFetching,
    isSuccess: isInvoiceDataSuccess,
    isError: isInvoiceDataError,
    error,
  } = useGetInvoicesQuery({
    Status: `ProcessingStatusId=${ProcessingStatus.FAILED}`,
    Query: query,
    Page: pageNumber,
    PageSize: pageSize,
  });

  const [deleteInvoice, { isLoading, isSuccess, isError }] =
    useDeleteInvoiceMutation();

  const [
    resubmitInvoice,
    {
      isLoading: resubmitInvoiceIsLoading,
      isSuccess: resubmitInvoiceIsSuccess,
      isError: resubmitInvoiceIsError,
    },
  ] = useResubmitInvoiceMutation();

  const handleSearch = (e) => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
    setQuery(e.target.value);
  };

  const showInvoice = (id) => {
    setInvoiceId(id);
    setIsOpen(true);
  };

  const toggelAssignToModal = () => {
    setAssignToModalIsOpen((prev) => !prev);
  };

  const handleDeleteInvoice = (id) => {
    setShowAlert(true);
    setInvoiceId(id);
  };

  const handleConfirmation = async (state) => {
    setShowAlert((prev) => !prev);
    if (state) {
      try {
        const response = await deleteInvoice(invoiceId);
        toast.success("Invoice Deleted");
      } catch (error) {
        showErrorToast(error);
      }
    }
  };

  const resubmitInvoiceOnClick = async (invoiceGuid) => {
    try {
      const response = await resubmitInvoice({
        guid: invoiceGuid,
      }).unwrap();

      if (response?.error) {
        toast.error(
          response.error.data?.title
            ? response.error.data?.title
            : "Something unexpected happened.",
          {
            duration: 4000,
          }
        );
        return;
      }
      toast.success("Invoice Re-Submitted Successful", {
        duration: 3000,
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const generateColumns = (data) => {
    if (data.length <= 0) {
      return;
    }
    const optionsConfig = {
      key: nanoid(),
      field: "options",
      headerText: "Options",
      template: (props) => (
        <div className="options-container">
          <button
            className="btn me-1 p-0 px-1 assignTo-btn"
            onClick={() => {
              toggelAssignToModal();
              setInvoiceGuid(props.id);
            }}
          >
            Assign To
          </button>
          <button
            className="btn me-1 p-0 px-1 resubmit-btn"
            onClick={() => resubmitInvoiceOnClick(props?.id)}
          >
            Re-submit
          </button>
          <button
            className="btn custom-icon-details me-1"
            onClick={() => navigate(`/invoice/view/${props["id"]}`)}
          ></button>
          <button
            className="btn custom-icon-edit me-1"
            onClick={() => navigate(`/invoice/edit/${props["id"]}`)}
            disabled={
              props.reviewStatusId === ReviewStatus.APPROVED ||
              props.reviewStatusId === ReviewStatus.AI_APPROVED ||
              props.processingStatusId === ProcessingStatus.READY_FOR_PROCESSING
            }
          ></button>
          <button
            className="btn custom-icon-delete me-1"
            onClick={() => handleDeleteInvoice(props["id"])}
          ></button>
        </div>
      ),
      width: "280px",
    };

    const columns = Object.keys(data[0])
      .map((field) => {
        if (field === "id") {
          return null;
        }
        if (field === "vatNumber") {
          return null;
        }
        if (field === "reviewStatusId") {
          return null;
        }
        if (field === "processingStatusId" || field === "processingStatus") {
          return null;
        }
        if (field === "netAmount") {
          return null;
        }

        if (field === "pageSettings") {
          return null;
        }

        if (field === "typeId") {
          return null;
        }

        let columnConfig = {
          key: nanoid(),
          field,
          headerText: field
            .replace(/([A-Z])/g, " $1")
            .trim()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          alignment: "left",
          template: null,
          width: "150px",
        };

        if (field === "invoiceNumber") {
          columnConfig.width = "160px";
        }
        if (field === "client") {
          columnConfig.headerText = "Supplier";
        }
        if (field === "vatAmount") {
          columnConfig.headerText = "VAT";
          columnConfig.width = "120px";
          columnConfig.alignment = "right";
        }
        if (field === "totalAmount") {
          columnConfig.headerText = "Total";
          columnConfig.width = "120px";
          columnConfig.alignment = "right";
        }
        if (field === "orderNumber") {
          columnConfig.width = "100px";
          columnConfig.headerText = "PO Number";
        }

        if (field === "assignedTo") {
          columnConfig.template = (props) => {
            return <span>{props[field]?.name ?? "N/A"}</span>;
          };
          columnConfig.width = "160px";
        }

        if (field === "reviewStatus") {
          columnConfig.template = (props) => {
            let style;
            switch (props.reviewStatusId) {
              case ReviewStatus.NEW:
                style = "new-badge";
                break;
              case ReviewStatus.DATA_EXTRACTED:
                style = "data-extracted-badge";
                break;
              case ReviewStatus.AI_APPROVED:
                style = "ai-approved-badge";
                break;
              case ReviewStatus.REVIEW_REQUIRED:
                style = "review-required-badge";
                break;
              case ReviewStatus.REVIEWED:
                style = "reviewed-badge";
                break;
              case ReviewStatus.APPROVAL_REQUIRED:
                style = "approval-required-badge";
                break;
              case ReviewStatus.APPROVED:
                style = "approved-badge";
                break;
              case ReviewStatus.REJECTED:
                style = "rejected-badge";
                break;
              default:
                style = "default-badge";
            }
            return (
              <span className={`status-badge ${style}`}>{props[field]}</span>
            );
          };
          columnConfig.width = "200px";
          columnConfig.alignment = "center";
        }

        // if (field === "processingStatus") {
        //   columnConfig.template = (props) => {
        //     let style;
        //     switch (props.processingStatusId) {
        //       case ProcessingStatus.NEW:
        //         style = "new-badge";
        //         break;
        //       case ProcessingStatus.UNDER_REVIEW:
        //         style = "under-review-badge";
        //         break;
        //       case ProcessingStatus.READY_FOR_PROCESSING:
        //         style = "ready-for-processing-badge";
        //         break;
        //       case ProcessingStatus.PROCESSING:
        //         style = "processing-badge";
        //         break;
        //       case ProcessingStatus.SUCCESSFUL:
        //         style = "successful-badge";
        //         break;
        //       case ProcessingStatus.FAILED:
        //         style = "failed-badge";
        //         break;
        //       default:
        //         style = "";
        //     }
        //     return (
        //       <div className="text-center">
        //         <span className={`status-badge ${style}`}>{props[field]}</span>
        //       </div>
        //     );
        //   };
        //   columnConfig.width = "150px";
        // }

        if (field === "type") {
          columnConfig.headerText = "";
          columnConfig.template = (props) => {
            let style;
            switch (props.typeId) {
              case InvoiceTypes.INVOICE:
                style = "invoice-badge";
                break;
              case InvoiceTypes.CREDIT_NOTE:
                style = "credit-note-badge";
                break;
              case InvoiceTypes.SAMPLE_INVOICE:
                style = "sample-invoice-badge";
                break;

              default:
                style = "";
            }
            return <div className={`${style}`} title={props[field]}></div>;
          };
          columnConfig.width = "20px";
        }

        return columnConfig;
      })
      .filter(Boolean);
    columns.splice(columns.length, 0, optionsConfig);
    return columns;
  };

  const handlePageChange = (page) => {
    if (page !== undefined || page !== null) {
      setPageNumber(page);
    }
  };

  useEffect(() => {
    if (invoiceData) {
      const data = invoiceData?.ids?.map((id) => invoiceData?.entities[id]);
      setInvoices(data);

      setColumns(generateColumns(data));
    }
    if (isInvoiceDataError) {
      showErrorToast(error);
    }
  }, [invoiceData, isInvoiceDataError]);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const resetAssignToAction = () => {
    setInvoiceGuid("");
  };

  return (
    <div className="invoice-manager failed">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {isInvoiceDataLoading && <LoadingSpinner />}

      <InvoiceInsights />

      <div className="content">
        <div className="custom-card invoice-container">
          <div className="row">
            <div className="col-12">
              <div className="invoice-table">
                <div className="row justify-content-end mb-3">
                  <div className="col-auto">
                    <div className="d-flex align-items-center">
                      <TableSearch
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        setQuery={setQuery}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  {invoices && invoices.length > 0 ? (
                    <DataTable
                      data={invoices}
                      columns={columns}
                      handlePageChange={handlePageChange}
                      currentPage={pageNumber}
                      height={780}
                    />
                  ) : (
                    "No invoices available"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal isOpen={isOpen} toggle={toggle} centered size="xl">
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <EditInvoice id={invoiceId} viewOnly={true} />
        </ModalBody>
      </Modal> */}

      <InvoiceAssigneeModal
        isOpen={assignToModalIsOpen}
        toggleModal={toggelAssignToModal}
        invoiceGuid={invoiceGuid}
        resetAction={resetAssignToAction}
      />

      <AlertModal isOpen={showAlert} toggle={handleConfirmation} />
    </div>
  );
};

export default FailedInvoices;
