import React, { useEffect, useState, useRef } from "react";
import PDFViewer from "../../PDFViewer/PDFViewer";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetInvoiceByGuidQuery,
  useUpdateInvoiceMutation,
  useCompleteManualReviewMutation,
  useRejectInvoiceMutation,
  useUpdateInvoiceClientMutation,
} from "../../../features/InvoiceManager/invoiceSlice";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../common/LoadingSpinner/LoadingSpinner";
import { UncontrolledTooltip } from "reactstrap";
import { ProcessingStatus, ReviewStatus } from "../../../enums/statusEnums";
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import "./EditInvoice.css";
import commentIcon from "../../../assets/images/Icons/commentIcon.svg";
import editIcon from "../../../assets/images/Icons/edit.svg";
import CancelIcon from "../../../assets/images/Icons/cancelIcon.png";
import collapseIcon from "../../../assets/images/Icons/collapseArrowIcon.svg";
import {
  useGetCommentsQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from "../../../features/Comments/commentsSlice";
import { useSelector } from "react-redux";
import {
  selectCurrentPermissions,
  selectCurrentUser,
} from "../../../features/auth/authSlice";
import { InvoiceTypes } from "../../../enums/invoiceTypes";
import InvoiceAssigneeModal from "../../InvoiceAssigneeModal/InvoiceAssigneeModal";
import { useErrorToast } from "../../../hooks/useErrorToast";
import { userPermissions } from "../../../enums/userPermissons";

const EditInvoice = ({ id = null, viewOnly = false, pageName = "" }) => {
  const navigate = useNavigate();

  const user = useSelector(selectCurrentUser);
  const permissions = useSelector(selectCurrentPermissions);
  const hasEditPermission = permissions?.some(
    (permission) => permission.guid === userPermissions.UPDATE_INVOICE
  );

  const params = useParams();
  const invoiceId = id ? id : params.id;

  const { data: getInvoiceByGuid, isLoading: getInvoiceByGuidIsLoading } =
    useGetInvoiceByGuidQuery(invoiceId);
  const [
    updateInvoice,
    {
      isLoading: updateInvoiceIsLoading,
      isSuccess: updateInvoiceIsSuccess,
      isError: updateInvoiceIsError,
      reset: updateInvoiceReset,
    },
  ] = useUpdateInvoiceMutation();
  const [
    completeManualReview,
    {
      isLoading: completeManualReviewIsLoading,
      isSuccess: completeManualReviewIsSuccess,
      isError: completeManualReviewIsError,
      reset: completeManualReviewReset,
    },
  ] = useCompleteManualReviewMutation();

  const [
    updateInvoiceClient,
    {
      isLoading: updateInvoiceClientIsLoading,
      isSuccess: updateInvoiceClientIsSuccess,
      isError: updateInvoiceClientIsError,
      error: updateInvoiceClientError,
    },
  ] = useUpdateInvoiceClientMutation();

  const [
    rejectInvoice,
    { isLoading: rejectInvoiceIsLoading, isSuccess: rejectInvoiceIsSuccess },
  ] = useRejectInvoiceMutation();

  const {
    data: comments,
    isFetching: commentsIsFetching,
    isLoading: commentsIsLoading,
    isError: commentsIsError,
    isSuccess: commentsIsSuccess,
  } = useGetCommentsQuery(invoiceId);

  const [
    addComment,
    {
      isLoading: addCommentIsLoading,
      isSuccess: addCommentIsSuccess,
      isError: addCommentIsError,
    },
  ] = useAddCommentMutation();
  const [
    updateComment,
    {
      isLoading: updateCommentIsLoading,
      isSuccess: updateCommentIsSuccess,
      isError: updateCommentIsError,
    },
  ] = useUpdateCommentMutation();
  const [
    deleteComment,
    {
      isLoading: deleteCommentIsLoading,
      isSuccess: deleteCommentIsSuccess,
      isError: deleteCommentIsError,
    },
  ] = useDeleteCommentMutation();

  const [activeStep, setActiveStep] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formattedInvoiceDate, setFormattedInvoiceDate] = useState("");
  const [invoice, setInvoice] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");

  const [vatNumber, setVatNumber] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [taxValue, setTaxValue] = useState(0);
  const [netValue, setNetValue] = useState(0);
  const [total, setTotal] = useState(0);

  const [companyNameError, setCompanyNameError] = useState(null);
  const [companyAddressError, setCompanyAddressError] = useState(null);
  const [invoiceNumberError, setInvoiceNumberError] = useState(null);
  const [invoiceDateError, setInvoiceDateError] = useState(null);
  const [vatNumberError, setVatNumberError] = useState(null);
  const [orderNumberError, setOrderNumberError] = useState(null);
  const [taxValueError, setTaxValueError] = useState(null);
  const [netValueError, setNetValueError] = useState(null);
  const [totalError, setTotalError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [actionType, setActionType] = useState("");
  const [isOpenRejectActionModal, setIsOpenRejectActionModal] = useState(false);
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const [addCommentIsVisible, setAddCommentIsVisible] = useState(false);
  const [commentDescription, setCommentDescription] = useState("");
  const [userComments, setUserComments] = useState([]);
  const [editingComment, setEditingComment] = useState(null);
  const [assignToModalIsOpen, setAssignToModalIsOpen] = useState(false);
  const [isChangeInvoiceFormsVisible, setIsChangeInvoiceFormsVisible] =
    useState(true);
  const showErrorToast = useErrorToast();
  const [invoiceClientName, setInvoiceClientName] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const clientNameInputRef = useRef(null);

  const fieldSets = [
    setCompanyName,
    setCompanyAddress,
    setInvoiceNumber,
    setInvoiceDate,
    setOrderNumber,
    setVatNumber,
    setNetValue,
    setTaxValue,
    setTotal,
  ];

  const errorSets = [
    setCompanyNameError,
    setCompanyAddressError,
    setInvoiceNumberError,
    setInvoiceDateError,
    setOrderNumberError,
    setVatNumberError,
    setNetValueError,
    setTaxValueError,
    setTotalError,
  ];

  const fields = [
    "Company Name",
    "Company Address",
    "Invoice Number",
    "Invoice Date",
    "Purchase Order",
    "Vat Number",
    "Net Amount",
    "Tax Value",
    "Total Amount",
  ];

  useEffect(() => {
    if (getInvoiceByGuid) {
      const data = getInvoiceByGuid?.ids?.map(
        (id) => getInvoiceByGuid?.entities[id]
      );
      setInvoice(data);
      setIsChangeInvoiceFormsVisible(
        data[0]?.reviewStatus?.id === ReviewStatus.REVIEWED ||
          data[0]?.reviewStatus?.id === ProcessingStatus.SUCCESSFUL
          ? false
          : true
      );
      setInvoiceClientName(data[0]?.clientName);
    }
  }, [getInvoiceByGuid]);

  useEffect(() => {
    if (invoice) {
      for (let i = 0; i < fields.length; i++) {
        var field = invoice[0].data?.filter(
          (item) => item.fieldName === fields[i]
        )[0];
        fieldSets[i](field?.fieldValue);
        errorSets[i](field?.errorMessage);
      }
    }
  }, [invoice]);

  useEffect(() => {
    if (invoiceDate) {
      let d = new Date(invoiceDate);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;

      if (month < 10) {
        month = "0" + month;
      }

      let date = d.getDate();
      if (date < 10) {
        date = "0" + date;
      }

      let fullDate = year + "-" + month + "-" + date;

      setFormattedInvoiceDate(fullDate);
    }
  }, [invoiceDate]);

  useEffect(() => {
    if (comments) {
      const data = comments?.ids?.map((id) => comments?.entities[id]);
      setUserComments(data);
    }
  }, [comments]);

  const handleStepClick = (stepNumber) => {
    setActiveStep(stepNumber);
  };

  const handleReset = () => {
    setCompanyName("");
    setCompanyAddress("");

    setInvoiceNumber("");
    setInvoiceDate("");

    setOrderNumber("");
    setVatNumber("");
    setTaxValue(0);
    setNetValue(0);
    setTotal(0);

    setCompanyNameError(null);
    setCompanyAddressError(null);
    setInvoiceNumberError(null);
    setInvoiceDateError(null);
    setOrderNumberError(null);
    setVatNumberError(null);
    setNetValueError(null);
    setTaxValueError(null);
    setTotalError(null);

    setActiveStep(1);
    navigate("/invoice-manager");
  };

  const handleUpdateInvoice = async () => {
    try {
      const formData = {
        number: invoiceNumber || null,
        date: formattedInvoiceDate || null,
        totalValue: parseFloat(total),
        netValue: parseFloat(netValue),
        taxValue: parseFloat(taxValue),
        vatNumber: vatNumber || null,
        purchaseOrderNumber: orderNumber || null,
        companyName: companyName || null,
        companyAddress: companyAddress || null,
      };

      await updateInvoice({
        guid: invoice[0].guid,
        data: formData,
      }).unwrap();

      return { status: true, message: "" };
    } catch (error) {
      return { status: false, message: error?.data?.Message };
    }
  };

  const handleCompleteReview = async () => {
    try {
      await completeManualReview(invoice[0].guid).unwrap();
      return { status: true, message: "" };
    } catch (error) {
      return { status: false, message: error?.data?.Message };
    }
  };

  const handleUpdateInvoiceClient = async (e) => {
    try {
      if (!invoiceClientName) {
        toast.error("Client name is required");
        return { status: false, message: "Client name is required" };
      }

      if (invoiceClientName === invoice[0]?.clientName) {
        return;
      }

      const updateClient = await updateInvoiceClient({
        guid: invoice[0].guid,
        clientName: invoiceClientName,
      }).unwrap();

      if (updateInvoiceClientError) {
        toast.error(updateClient.message);
        return { status: false, message: updateClient.message };
      }

      toast.success("Client name updated successfully");
      setIsEditable(false);
      return { status: true, message: "Client name updated" };
    } catch (error) {
      toast.error(
        error?.data?.Message ||
          "Unable to update the client name. Please try again"
      );
      return {
        status: false,
        message:
          error?.data?.Message ||
          "Unable to update the client name. Please try again",
      };
    }
  };

  const handleSaveInvoice = async () => {
    const updateResponse = await handleUpdateInvoice();
    if (updateResponse.status) {
      toast.success("Invoice Updated Successfully");
    } else {
      toast.error(updateResponse.message);
    }
  };

  const toggle = () => {
    setModalIsOpen((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setActionType("save");
    setAlertMessage(
      "Are you sure you want to Save & Complete review this invoice ?"
    );
    toggle();
  };

  const handleRejectInvoiceConfirmation = () => {
    setActionType("reject");
    setAlertMessage("Are you sure you want to Reject this invoice ?");
    toggle();
  };

  const resetActions = () => {
    toggle();
    setActionType("");
    setAlertMessage("");
  };

  const handleSaveAndReview = async (e) => {
    resetActions();
    const updateResponse = await handleUpdateInvoice();
    if (updateResponse.status) {
      const reviewResponse = await handleCompleteReview();
      if (reviewResponse.status) {
        toast.success("Invoice is Saved & Review Completed Successfully");
        setTimeout(() => {
          navigate("/invoice-manager");
        }, 2000);
      } else {
        toast.error(reviewResponse.message);
      }
    } else {
      toast.error(updateResponse.message);
    }
  };

  const showRejectActionModal = () => {
    setIsOpenRejectActionModal((prev) => !prev);
  };

  const handleRejectInvoice = () => {
    toggle();
    showRejectActionModal();
  };

  const handleRejectSubmit = async (e) => {
    e.preventDefault();
    try {
      await rejectInvoice({
        guid: invoiceId,
        data: {
          guid: invoiceId,
          comment: comment,
          reason: reason,
        },
      }).unwrap();
      toast.success("Invoice Rejected Successfully");
      setComment("");
      setReason("");
      showRejectActionModal();
    } catch (error) {
      showErrorToast(error);
    }
  };

  const postComment = async (e) => {
    e.preventDefault();
    try {
      const data = {
        comment: commentDescription,
      };

      if (editingComment) {
        await updateComment({ ...data, guid: editingComment.guid }).unwrap();
        setEditingComment(null);
        toast.success("Comment updated successfully");
      } else {
        await addComment({ ...data, guid: invoiceId }).unwrap();
        toast.success("Comment posted successfully");
      }

      setCommentDescription("");
      setAddCommentIsVisible(false);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleDeleteComment = async (id) => {
    try {
      await deleteComment(id).unwrap();
      toast.success("Comment deleted");
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleUpdateComment = (comment) => {
    setAddCommentIsVisible(true);
    setCommentDescription(comment.description);
    setEditingComment(comment);
  };

  const generateTypeTag = (type) => {
    let style;
    switch (type?.id) {
      case InvoiceTypes.INVOICE:
        style = "invoice-badge";
        break;
      case InvoiceTypes.CREDIT_NOTE:
        style = "credit-note-badge";
        break;
      case InvoiceTypes.SAMPLE_INVOICE:
        style = "sample-invoice-badge";
        break;

      default:
        style = "";
    }
    return <div className={`${style}`}>{type?.name}</div>;
  };

  const toggelAssignToModal = () => {
    setAssignToModalIsOpen((prev) => !prev);
  };

  const handleEditClientButtonClick = () => {
    setIsEditable(true);
    setTimeout(() => {
      if (clientNameInputRef.current) {
        clientNameInputRef.current.focus();
      }
    }, 0);
  };

  return (
    <div>
      {(updateInvoiceIsLoading ||
        completeManualReviewIsLoading ||
        commentsIsLoading ||
        addCommentIsLoading ||
        deleteCommentIsLoading ||
        updateInvoiceClientIsLoading ||
        updateCommentIsLoading) && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="row g-3">
        <div className="col-12 col-md-7">
          <div
            className="custom-card align-self-stretch"
            style={{ overflow: "auto" }}
          >
            <PDFViewer invoiceId={invoiceId} height={"95vh"} />
          </div>
        </div>

        <div className="col-12 col-md-5">
          <div
            className={`custom-card d-flex align-items-center justify-content-start mb-1 py-2`}
          >
            <div>
              <span className="fw-bold">Assigned To :</span>
              <span className="assignTo-btn rounded mx-1 py-1 px-2">
                {`${invoice[0]?.assignedTo?.name ?? "N/A"}`}
              </span>
              <button
                className="btn custom-icon-edit me-1"
                onClick={() => {
                  toggelAssignToModal();
                }}
              ></button>
            </div>
          </div>
          <div className="custom-card my-1">
            <div className="row align-items-center">
              <div className="col-12 col-md-auto">
                <span className="fw-bold px-0">Client Name :</span>
              </div>
              <div className="col-12 col-md px-0" style={{ width: "100%" }}>
                {isEditable ? (
                  <>
                    <input
                      id="clientName"
                      type="text"
                      className="form-control invoice-search form-control-sm"
                      value={invoiceClientName}
                      placeholder="Client name"
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[A-Za-z\s]*$/;
                        if (regex.test(value)) {
                          setInvoiceClientName(value);
                        }
                      }}
                      readOnly={!isEditable}
                      required
                    />
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="fw-bold m-0">{invoiceClientName}</p>
                    </div>
                    <div className="px-1">
                      <button
                        className="btn custom-icon-edit p-0 m-0"
                        onClick={handleEditClientButtonClick}
                        title="Edit client name"
                      ></button>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-auto mt-2 mt-md-0 p-1 edit-client-btn-group">
                {isEditable ? (
                  <div>
                    <div className="d-flex">
                      <div>
                        <button
                          className="btn p-0 m-0"
                          onClick={() => setIsEditable(false)}
                          title="Cancel"
                        >
                          <img
                            src={CancelIcon}
                            width={25}
                            alt="collapse icon"
                            className="cancel-icon"
                          />
                        </button>
                      </div>
                      <div className="px-1">
                        <button
                          className="edit-invoice-client-btn"
                          onClick={() => {
                            if (invoiceClientName) {
                              setIsEditable(true);
                              handleUpdateInvoiceClient();
                            }
                          }}
                          title="Save client name"
                        >
                          {/* <img
                        src={SaveIcon}
                        width={17}
                        height={17}
                        alt="collapse icon"
                      /> */}
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <h6 className="custom-card invoice-edit-title d-flex justify-content-between mb-1">
            <div>
              {viewOnly ? "" : "Change"} Invoice Details{" "}
              {viewOnly && hasEditPermission ? (
                <button
                  className="btn btn-blue btn-sm ms-2"
                  onClick={() => navigate(`/invoice/edit/${invoiceId}`)}
                >
                  <span className="me-2">Edit</span>
                  <img
                    src={editIcon}
                    width={18}
                    height={18}
                    alt="collapse icon"
                  />
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex gap-2 align-items-center">
              {generateTypeTag(invoice[0]?.category)}
              <button
                className={`btn btn-sm collapse-btn ${
                  isChangeInvoiceFormsVisible ? "" : "closed"
                }`}
                title={`${
                  isChangeInvoiceFormsVisible ? "Collapse" : "Expand"
                } invoice details`}
                onClick={() => setIsChangeInvoiceFormsVisible((prev) => !prev)}
              >
                <img
                  src={collapseIcon}
                  width={12}
                  height={12}
                  alt="collapse icon"
                />
              </button>
            </div>
          </h6>
          <form onSubmit={handleSubmit}>
            <div
              className={`row g-1 ${
                !isChangeInvoiceFormsVisible ? "d-none" : ""
              }`}
            >
              <div className="col-12" onClick={() => handleStepClick(1)}>
                <div
                  className={`custom-card  d-flex flex-column flex-grow-1 ${
                    activeStep === 1 ? "active-box" : ""
                  }`}
                >
                  {/* <h6 className="invoice-edit-title d-flex justify-content-between">
                    {viewOnly ? "" : "Change"} Invoice Details
                    {generateTypeTag(invoice[0]?.category)}
                    <button
                      className={`btn btn-sm collapse-btn ${
                        isChangeInvoiceFormsVisible ? "" : "closed"
                      }`}
                      title={`${
                        isChangeInvoiceFormsVisible ? "Collapse" : "Expand"
                      } invoice details`}
                      onClick={() =>
                        setIsChangeInvoiceFormsVisible((prev) => !prev)
                      }
                    >
                      <img src={collapseIcon} width={12} alt="collapse icon" />
                    </button>
                  </h6> */}
                  <div className="step-container">
                    <div className={`step ${activeStep === 1 ? "active" : ""}`}>
                      <span className="step-number">1</span>
                      <span className="step-title">Customer Details</span>
                    </div>
                    <div className={`step ${activeStep === 2 ? "active" : ""}`}>
                      <span className="step-number">2</span>
                      <span className="step-title">Invoice Details</span>
                    </div>
                    <div className={`step ${activeStep === 3 ? "active" : ""}`}>
                      <span className="step-number">3</span>
                      <span className="step-title">Order Details</span>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-2">
                          <label htmlFor="client" className="edit-title">
                            Company Name
                          </label>
                          <input
                            id="companyName"
                            type="text"
                            className={`form-control invoice-search form-control-sm ${
                              companyNameError ? "is-invalid" : ""
                            }`}
                            placeholder="Company Name"
                            value={companyName || ""}
                            onChange={(e) => setCompanyName(e.target.value)}
                            disabled={viewOnly}
                          />
                          {companyNameError ? (
                            <UncontrolledTooltip
                              placement="top"
                              target="companyName"
                              trigger="click"
                            >
                              {companyNameError}
                            </UncontrolledTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2">
                          <label
                            htmlFor="companyAddress"
                            className="edit-title"
                          >
                            Company Address
                          </label>
                          <input
                            id="companyAddress"
                            type="text"
                            className={`form-control invoice-search form-control-sm ${
                              companyAddressError ? "is-invalid" : ""
                            }`}
                            placeholder="Company Address"
                            value={companyAddress || ""}
                            onChange={(e) => setCompanyAddress(e.target.value)}
                            disabled={viewOnly}
                          />
                          {companyAddressError ? (
                            <UncontrolledTooltip
                              placement="top"
                              target="companyAddress"
                              trigger="click"
                            >
                              {companyAddressError}
                            </UncontrolledTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12" onClick={() => handleStepClick(2)}>
                <div
                  className={`custom-card  d-flex flex-column flex-grow-1 ${
                    activeStep === 2 ? "active-box" : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-2">
                        <label htmlFor="invoiceNumber" className="edit-title">
                          Invoice Number
                        </label>
                        <div className="input-group">
                          <input
                            id="invoiceNumber"
                            type="text"
                            className={`form-control invoice-search form-control-sm ${
                              invoiceNumberError ? "is-invalid" : ""
                            }`}
                            placeholder="Invoice Number"
                            value={invoiceNumber || ""}
                            onChange={(e) => setInvoiceNumber(e.target.value)}
                            disabled={viewOnly}
                          />
                          {invoiceNumberError ? (
                            <UncontrolledTooltip
                              placement="top"
                              target="invoiceNumber"
                              trigger="click"
                            >
                              {invoiceNumberError}
                            </UncontrolledTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2">
                        <label htmlFor="invoiceDate" className="edit-title">
                          Invoice Date
                        </label>
                        <input
                          id="invoiceDate"
                          type="date"
                          style={{ cursor: "pointer" }}
                          className={`form-control invoice-search form-control-sm ${
                            invoiceDateError ? "is-invalid" : ""
                          }`}
                          value={formattedInvoiceDate || ""}
                          onChange={(e) =>
                            setFormattedInvoiceDate(e.target.value)
                          }
                          disabled={viewOnly}
                        />
                        {invoiceDateError ? (
                          <UncontrolledTooltip
                            placement="top"
                            target="invoiceDate"
                            trigger="click"
                          >
                            {invoiceDateError}
                          </UncontrolledTooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12" onClick={() => handleStepClick(3)}>
                <div
                  className={`custom-card  d-flex flex-column flex-grow-1 ${
                    activeStep === 3 ? "active-box" : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-2">
                        <label htmlFor="orderNumber" className="edit-title">
                          Order Number
                        </label>
                        <div className="input-group">
                          <input
                            id="orderNumber"
                            type="text"
                            className={`form-control invoice-search form-control-sm ${
                              orderNumberError ? "is-invalid" : ""
                            }`}
                            placeholder="Order Number"
                            value={orderNumber || ""}
                            onChange={(e) => setOrderNumber(e.target.value)}
                            disabled={viewOnly}
                          />
                          {orderNumberError ? (
                            <UncontrolledTooltip
                              placement="top"
                              target="orderNumber"
                              trigger="click"
                            >
                              {orderNumberError}
                            </UncontrolledTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2">
                        <label htmlFor="vatNumber" className="edit-title">
                          VAT Number
                        </label>
                        <div className="input-group">
                          <input
                            id="vatNumber"
                            type="text"
                            className={`form-control invoice-search form-control-sm ${
                              vatNumberError ? "is-invalid" : ""
                            }`}
                            placeholder="VAT Number"
                            value={vatNumber || ""}
                            onChange={(e) => setVatNumber(e.target.value)}
                            disabled={viewOnly}
                          />
                          {vatNumberError ? (
                            <UncontrolledTooltip
                              placement="top"
                              target="vatNumber"
                              trigger="click"
                            >
                              {vatNumberError}
                            </UncontrolledTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="tax" className="edit-title">
                          Tax Value
                        </label>
                        <input
                          id="tax"
                          type="text"
                          className={`form-control invoice-search form-control-sm ${
                            taxValueError ? "is-invalid" : ""
                          }`}
                          value={taxValue}
                          onChange={(e) => setTaxValue(e.target.value)}
                          disabled={viewOnly}
                        />
                        {taxValueError ? (
                          <UncontrolledTooltip
                            placement="top"
                            target="tax"
                            trigger="click"
                          >
                            {taxValueError}
                          </UncontrolledTooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="net" className="edit-title">
                          Net Value
                        </label>
                        <input
                          id="net"
                          type="text"
                          className={`form-control invoice-search form-control-sm ${
                            netValueError ? "is-invalid" : ""
                          }`}
                          value={netValue}
                          onChange={(e) => setNetValue(e.target.value)}
                          disabled={viewOnly}
                        />
                        {netValueError ? (
                          <UncontrolledTooltip
                            placement="top"
                            target="net"
                            trigger="click"
                          >
                            {netValueError}
                          </UncontrolledTooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="total" className="edit-title">
                          Total
                        </label>
                        <input
                          id="total"
                          type="text"
                          className={`form-control invoice-search form-control-sm ${
                            totalError ? "is-invalid" : ""
                          }`}
                          value={total}
                          onChange={(e) => setTotal(e.target.value)}
                          disabled={viewOnly}
                        />
                        {totalError ? (
                          <UncontrolledTooltip
                            placement="top"
                            target="total"
                            trigger="click"
                          >
                            {totalError}
                          </UncontrolledTooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {!viewOnly && (
                      <div className="row g-2" style={{ marginLeft: 2 }}>
                        <div className="col-12 col-md">
                          <button
                            type="reset"
                            className="btn btn-reset cancel btn-sm w-100  h-100"
                            onClick={handleReset}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-12 col-md">
                          <button
                            type="button"
                            className="btn btn-filter btn-sm w-100 h-100"
                            disabled={
                              updateInvoiceIsLoading ||
                              getInvoiceByGuidIsLoading ||
                              invoice[0]?.reviewStatus?.id ===
                                ReviewStatus.APPROVED ||
                              invoice[0]?.reviewStatus?.id ===
                                ReviewStatus.AI_APPROVED
                            }
                            onClick={handleSaveInvoice}
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-12 col-md">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm w-100 h-100"
                            disabled={
                              completeManualReviewIsLoading ||
                              getInvoiceByGuidIsLoading ||
                              invoice[0]?.reviewStatus?.id ===
                                ReviewStatus.REJECTED ||
                              invoice[0]?.reviewStatus?.id ===
                                ReviewStatus.AI_APPROVED ||
                              invoice[0]?.reviewStatus?.id ===
                                ReviewStatus.APPROVED
                            }
                            onClick={handleRejectInvoiceConfirmation}
                          >
                            Reject Review
                          </button>
                        </div>
                        <div className="col-12 col-md">
                          <button
                            type="submit"
                            className="btn btn-success btn-sm w-100  h-100"
                            disabled={
                              updateInvoiceIsLoading ||
                              completeManualReviewIsLoading ||
                              invoice[0]?.reviewStatus?.id !==
                                ReviewStatus.REVIEW_REQUIRED
                            }
                          >
                            Save & Complete Review
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>

          {commentsIsSuccess ? (
            <div className={`custom-card  d-flex flex-column flex-grow-1 mt-1`}>
              <div className="d-flex justify-content-between align-items-center mb-1">
                <h6>Comments</h6>
                <button
                  onClick={() => {
                    setAddCommentIsVisible((prev) => !prev);
                    setEditingComment(null);
                    setCommentDescription("");
                  }}
                  className={`btn  btn-sm ${
                    addCommentIsVisible ? "btn-grey" : "btn-blue"
                  }`}
                >
                  {addCommentIsVisible ? "Close" : "Add Comment"}
                </button>
              </div>
              <div className="mb-2">
                {addCommentIsVisible && (
                  <form onSubmit={postComment}>
                    <div className="mb-2">
                      <textarea
                        id="userComments"
                        className="form-control"
                        rows={3}
                        placeholder="Type your comments..."
                        required
                        value={commentDescription}
                        onChange={(e) => setCommentDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="row gap-1">
                      <div className="col-12 col-md">
                        <button
                          type="reset"
                          className="btn btn-grey btn-sm w-100 h-100"
                          onClick={() => {
                            setCommentDescription("");
                            setEditingComment(null);
                          }}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="col-12 col-md">
                        <button
                          type="submit"
                          className="btn btn-blue btn-sm w-100 h-100"
                        >
                          {editingComment ? "Update Comment" : "Comment"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
              {userComments.length > 0 ? (
                <div className="comment-section">
                  {userComments.map((comment) => (
                    <div
                      key={comment.guid}
                      className="bg-light rounded py-1 px-2 mb-2 border border-1"
                    >
                      <div className="d-flex align-items-start rounded px-1">
                        <img
                          src={commentIcon}
                          className="ms-0 m-2"
                          width={16}
                          alt=""
                        />
                        <p className="comment-description m-0 px-2 fw-bold">
                          {comment.description}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mx-1">
                        <div className="">
                          <small>By: {comment.createdBy.name}</small>
                        </div>
                        <div className="d-flex align-items-center">
                          <small className="me-2">
                            On: {comment.createdOn}
                          </small>
                          {user.guid === comment?.createdBy?.guid && (
                            <div className="text-end">
                              <button
                                className="btn btn-sm custom-icon-edit mb-1 me-1"
                                onClick={() => handleUpdateComment(comment)}
                              ></button>
                              <button
                                className="btn btn-smm custom-icon-delete mb-1"
                                onClick={() =>
                                  handleDeleteComment(comment.guid)
                                }
                              ></button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                "No comments available..."
              )}
            </div>
          ) : (
            "Something went wrong while fetching comments."
          )}

          <ConfirmationModal
            toggle={toggle}
            message={alertMessage}
            isOpen={modalIsOpen}
            action={
              actionType === "save" ? handleSaveAndReview : handleRejectInvoice
            }
            type={actionType}
          />
          <Modal
            isOpen={isOpenRejectActionModal}
            toggle={showRejectActionModal}
            backdrop={"static"}
          >
            {rejectInvoiceIsLoading && <LoadingSpinner />}
            <ModalHeader>{`Reject invoice`}</ModalHeader>
            <ModalBody>
              <form onSubmit={handleRejectSubmit}>
                <div className="mb-2">
                  <label htmlFor="rejectReason" className="edit-title">
                    Reason for Rejection
                  </label>
                  <input
                    id="rejectReason"
                    type="text"
                    className="form-control invoice-search form-control-sm"
                    placeholder="Reject Reason"
                    value={reason || ""}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="comments" className="edit-title">
                    Comments/Notes
                  </label>
                  <textarea
                    className="form-control"
                    name=""
                    id=""
                    rows={5}
                    value={comment || ""}
                    onChange={(e) => setComment(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <button
                        type="reset"
                        className="btn btn-reset cancel me-2 btn-sm"
                        onClick={showRejectActionModal}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-filter btn-sm">
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
          <InvoiceAssigneeModal
            isOpen={assignToModalIsOpen}
            toggleModal={toggelAssignToModal}
            invoiceGuid={invoiceId}
            resetAction={() => {}}
            pageName={pageName}
          />
        </div>
      </div>
    </div>
  );
};

export default EditInvoice;
