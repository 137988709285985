import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/images/Icons/searchwhite.svg";
import closeIcon from "../../assets/images/Icons/closeIconwhite.svg";
import "./TableSearch.css";

const TableSearch = ({ pageNumber, setPageNumber, setQuery }) => {
  const [searchText, setSearchText] = useState("");
  const [isClearVisible, setIsClearVisible] = useState(false);

  useEffect(() => {
    if (searchText !== "") {
      setIsClearVisible(true);
    } else {
      setIsClearVisible(false);
    }
  }, [searchText]);

  const handleSearch = () => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
    setQuery(searchText);
  };

  const handleClearSearch = () => {
    setIsClearVisible(false);
    setSearchText("");
    setQuery("");
  };

  return (
    <div className="input-group table-search">
      <input
        id="invoiceSearch"
        type="text"
        className="form-control invoice-search"
        placeholder="Search Invoice"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      <label
        htmlFor="invoiceSearch"
        className="input-group-text search-icon-container"
      >
        {isClearVisible && (
          <button className="btn btn-search close" onClick={handleClearSearch}>
            <img
              src={closeIcon}
              alt="Close Icon"
              className="icon-image"
              width={16}
            />
          </button>
        )}
        <button className="btn btn-search" onClick={handleSearch}>
          <img
            src={searchIcon}
            alt="Search Icon"
            className="icon-image"
            width={16}
          />
        </button>
      </label>
    </div>
  );
};

export default TableSearch;
